import React, { FC, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Order } from '@Types/cart/Order';
import InfiniteScroll from 'react-infinite-scroll-component';
import useSWR, { mutate } from 'swr';
import Price from 'components/commercetools-ui/price';
import Spinner from 'components/commercetools-ui/spinner';
import { GA_ORDER_HISTORY } from 'helpers/constants/aafes';
import useGATrackUserAction from 'helpers/hooks/gaEvents/useGATrackUserAction';
import { useFormat } from 'helpers/hooks/useFormat';
import { formatDateCDTToLocal } from 'helpers/utils/formatDateCDTToLocal';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import { useAccount, useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
export interface Props {
  orders?: Order[];
}

const OrdersHistory: FC<Props> = ({ orders }) => {
  const [accountOrdersState, setAccountOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [targetTimeframe, setTargetTimeframe] = useState('last30Days');
  const [PageSize, setPageSize] = useState<number>(20);
  const router = useRouter();
  const { account } = useAccount();
  const { trackMyAccount } = useGATrackUserAction();
  //account data
  const { orderHistory, data: cartData } = useCart();
  const { studioConfig, setCartProductDetailEventSent } = useCart();
  const orderHistoryImage = studioConfig?.orderHistoryImage;
  const [isFetchOrder, setIsFetchOrder] = useState<boolean>(
    sessionStorage.getItem('isRestrictGetOrders') ? false : true,
  );

  //18in messages
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });

  function fetchOrderHistory() {
    return orderHistory(targetTimeframe, PageSize, currentPage);
  }

  const revalidateOptions = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
    revalidateOnMount: isFetchOrder,
  };
  const { data } = useSWR(
    ['/action/cart/getOrders', targetTimeframe, PageSize, currentPage, account?.accountId, isFetchOrder],
    fetchOrderHistory,
    revalidateOptions,
  );

  useEffect(() => {
    if (data?.errorCode) {
      setAccountOrders([]);
      setLoading(false);
    } else if (data) {
      const newData = data?.EntityList?.map((element) => {
        return {
          ...element,
          shownProducts: element.OrderLine.slice(0, 4),
        };
      });
      const updatedOrder = [...accountOrdersState, ...newData];
      if (updatedOrder?.length >= data?.TotalNoRecords) setHasMoreData(false);
      setAccountOrders(updatedOrder);
      setLoading(false);
    }
    if (sessionStorage.getItem('isRestrictGetOrders') && !data) {
      setIsFetchOrder(true);
    }
    sessionStorage.removeItem('isRestrictGetOrders');
  }, [data]);

  const handleFilter = async (e) => {
    setLoading(true);
    setAccountOrders([]);
    setTargetTimeframe(e?.target?.value);
    setHasMoreData(true);
    setCurrentPage(0);
  };

  const loadMoreOrders = async () => {
    if (hasMoreData) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    trackMyAccount(GA_ORDER_HISTORY, 'Order History Page', account);
  }, []);
  const [monetateConfig, setMonetateConfig] = useState(null);
  const [monetateSent, setMonetateSent] = useState(false);
  useEffect(() => {
    if (studioConfig?.monetateConstants) {
      try {
        const monetateConfig = JSON?.parse(studioConfig?.monetateConstants?.replace(/'/g, '"'));
        setMonetateConfig(monetateConfig);
      } catch (error) {
        console.error('Errorparsing projectConfig:', error);
      }
    }
  }, [studioConfig]);

  useEffect(() => {
    const processMonetate = async () => {
      const callMonetate = async () => {
        setMonetateSent(true);
        await sendToMonetate(
          {
            page: 'order_history',
            cart: {
              ...cartData,
            },
          },
          monetateConfig,
        );
      };
      if (orderHistory && monetateConfig && !monetateSent) {
        await callMonetate();
        setCartProductDetailEventSent(true);
      }
    };
    const fetchData = async () => {
      await processMonetate();
    };
    fetchData();
  }, [account, monetateConfig]);

  return (
    <>
      <div className="mb-3">
        <div aria-labelledby="order-history-heading" className="mb-[21px] items-center md:flex md:justify-between">
          <div>
            <h3
              id="order-history-heading"
              className="mb-[13px] text-[21px] font-bold leading-[25.2px] text-[#333333] md:mb-0"
            >
              {formatAccountMessage({
                id: 'orders.history',
                defaultMessage: 'Order History',
              })}
            </h3>
          </div>
          <div className="flex justify-end md:w-[286px]">
            <div className="flex w-[286px] justify-end">
              <select
                onChange={handleFilter}
                className="select h-[36px] w-full max-w-[160px] items-center whitespace-nowrap rounded border border-[rgba(0,0,0,0.2)] pl-[10px] pr-[15px] text-sm leading-normal text-[#333333] focus:border-none  focus:outline-none  lg:max-w-[172px]"
              >
                <option value={'last30Days'}>
                  {' '}
                  {formatAccountMessage({
                    id: 'last30Days',
                    defaultMessage: 'Last 30 Days',
                  })}
                </option>
                <option value={'past3Months'}>
                  {formatAccountMessage({
                    id: 'past3Months',
                    defaultMessage: 'Past 3 Months',
                  })}
                </option>
                <option value={'2024'}>
                  {formatAccountMessage({
                    id: '2024',
                    defaultMessage: '2024',
                  })}
                </option>
                <option value={'2023'}>
                  {formatAccountMessage({
                    id: '2023',
                    defaultMessage: '2023',
                  })}
                </option>
                <option value={'2022'}>
                  {' '}
                  {formatAccountMessage({
                    id: '2022',
                    defaultMessage: '2022',
                  })}
                </option>
                <option value={'2021'}>
                  {' '}
                  {formatAccountMessage({
                    id: '2021',
                    defaultMessage: '2021',
                  })}
                </option>
                <option value={'upTo7Years'}>
                  {' '}
                  {formatAccountMessage({
                    id: 'upto7years',
                    defaultMessage: 'up to 7 years',
                  })}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div>
        {loading ? (
          <div className="flex items-stretch justify-center py-10 px-12">
            <Spinner />
          </div>
        ) : accountOrdersState && accountOrdersState?.length ? (
          <section aria-labelledby="recent-heading" className="mt-2">
            <h2 id="recent-heading" className="sr-only">
              {formatAccountMessage({ id: 'recentOrder', defaultMessage: 'Recent Orders' })}
            </h2>
            <InfiniteScroll
              className="grid"
              dataLength={accountOrdersState.length}
              next={loadMoreOrders}
              hasMore={hasMoreData}
              loader={
                <div className="mt-3 justify-self-center rounded-[3px] border border-[#043C63] bg-blue-900 p-[10px] text-center text-sm font-normal leading-[16.8px] text-white">
                  Loading .....{' '}
                </div>
              }
              endMessage={
                <div className="mt-3 justify-self-center rounded-[3px] border border-[#043C63] bg-blue-900 p-[10px] text-center text-sm font-normal leading-[16.8px] text-white">
                  No more orders available.
                </div>
              }
            >
              <ul className="space-y-4 sm:space-y-4">
                {accountOrdersState?.map((order, index) => (
                  <li className="rounded border border-[rgba(0,0,0,0.2)] py-4 px-[13px] sm:border" key={order.orderId}>
                    <div className="mb-[18px] flex  justify-between gap-2 text-sm sm:justify-start md:flex-row">
                      <dt className="inline text-sm font-normal leading-[16.8px] text-[#333333]">
                        {formatAccountMessage({
                          id: 'orders.id',
                          defaultMessage: 'Order ID',
                        })}
                        :{' '}
                        <Link
                          href={{
                            pathname: '/account',
                            hash: '#order-detail',
                            query: { id: order.OrderId },
                          }}
                        >
                          <span className="ml-1 cursor-pointer font-normal leading-[16.8px] text-[#043C63]">
                            {order?.OrderId}
                          </span>
                        </Link>
                      </dt>
                      <div className="flex">
                        <dt className="mr-1 ">
                          <p className="text-sm font-normal leading-[16.8px] text-[#333333]">
                            {formatAccountMessage({
                              id: 'orderDate',
                              defaultMessage: 'Order Date',
                            })}
                            :
                          </p>
                        </dt>
                        <p className="text-sm font-normal leading-[16.8px] text-[#333333]">
                          {(() => {
                            const formattedDate = new Intl.DateTimeFormat('en-US', {
                              month: 'short',
                              day: '2-digit',
                              year: 'numeric',
                            }).format(formatDateCDTToLocal(order?.CapturedDate));
                            const [month, day, year] = formattedDate.replace(',', '').split(' ');
                            return `${day} ${month} ${year}`;
                          })()}
                        </p>
                      </div>
                    </div>
                    <div className="w-full text-gray-500">
                      <caption className="sr-only">
                        {formatProductMessage({
                          id: 'products',
                          defaultMessage: 'Products',
                        })}
                      </caption>
                      <div className=" flex-col md:flex md:justify-between">
                        <div className="block  justify-between md:flex">
                          <div className="flex">
                            <div className=" flex items-center  justify-between gap-x-1  ">
                              <div className="flex flex-row gap-4 text-sm">
                                {order?.shownProducts?.slice(0, 3).map((product) => (
                                  <>
                                    <div key={product?.ItemId}>
                                      <Link href={`slug/p/${product?.ItemId}`}>
                                        <a className="text-accent-400">
                                          <span className="sr-only"> {product?.ItemShortDescription}</span>
                                          <div className="relative  inline-flex  h-[50px] w-[50px] items-center rounded-lg text-center   text-sm font-medium text-white focus:outline-none  md:mb-0">
                                            {product?.SmallImageURI !== null ? (
                                              <Image
                                                src={product?.SmallImageURI}
                                                alt={product?.ItemShortDescription}
                                                className=" h-[50px] w-[50px]  rounded object-cover"
                                              />
                                            ) : (
                                              <Image
                                                src={orderHistoryImage}
                                                alt={product?.ItemShortDescription}
                                                className=" h-[50px] w-[50px]  rounded object-cover"
                                              />
                                            )}

                                            {product?.Quantity > 1 && (
                                              <div className="absolute top-[-12px] right-[-6px] h-6 w-6 rounded-[50%] border-2 border-[#ffffff] bg-[#043C63]">
                                                <div className="text-[14px] font-bold text-[#ffffff] ">
                                                  {product?.Quantity}
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </a>
                                      </Link>
                                    </div>
                                  </>
                                ))}

                                <div>
                                  {order?.OrderLine?.length > 4 && (
                                    <section className="relative w-10/12 lg:ml-10">
                                      {order?.OrderLine?.length - order?.shownProducts?.length !== 0 ? (
                                        <div className=" w-[98px] border-[rgba(0,0,0,0.2)]">
                                          <Link
                                            href={{
                                              pathname: '/account',
                                              hash: '#order-detail',
                                              query: { id: order.OrderId },
                                            }}
                                          >
                                            <button className="h-[50px] w-full rounded border py-4  text-sm text-[#043C63]">
                                              {' '}
                                              {order?.OrderLine?.length - order?.shownProducts?.length + 1}{' '}
                                              {formatCommonMessage({
                                                id: 'moreItems',
                                                defaultMessage: 'more items',
                                              })}
                                            </button>
                                          </Link>
                                        </div>
                                      ) : (
                                        <p></p>
                                      )}
                                    </section>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="md:gap[15px] mt-6 flex flex-col gap-[20px] md:mt-0 md:items-end md:justify-end">
                            <Price
                              price={{
                                fractionDigits: 2,
                                centAmount: order?.OrderTotal * 100,
                                currencyCode: order?.CurrencyCode,
                              }}
                              className=" text-sm font-bold leading-[16.8px] text-[#333333] "
                            />
                            <Link
                              href={{
                                pathname: '/account',
                                hash: '#order-detail',
                                query: { id: order.OrderId },
                              }}
                            >
                              <a
                                className="w-full cursor-pointer rounded-[3px] border border-[#043C63] p-[10px] py-[10px] text-center text-[14px] font-bold text-[#043C63]"
                                role="link"
                                aria-labelledby="View Order Details"
                              >
                                View Order Details
                              </a>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </InfiniteScroll>
          </section>
        ) : (
          <p className="mt-10 max-w-2xl text-[14px] font-bold leading-5 text-[#333]">
            {formatAccountMessage({
              id: 'orders.no.orders',
              defaultMessage: 'You have no orders.',
            })}
          </p>
        )}
      </div>
    </>
  );
};

export default OrdersHistory;
