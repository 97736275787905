import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Loader from 'components/commercetools-ui/loader/Loader';
import { EGIFT_CUSTOM_PRODUCTS, MILSTAR_CARD, GIFT_CARD_TYPE, FRONT_SMALL } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import { getSeparatedDeliverySections } from 'helpers/utils/getSeparatedDeliverySections';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import { getCreditCardInfo } from 'helpers/utils/orderConfirmation';
import { isPreOrderable } from 'helpers/utils/preOrderable';
import { isParentBundleLineItem } from 'helpers/utils/ub-utils';
import { useAccount, useCart } from 'frontastic';
import CheckShippingPickupIconActive from '../../../icons/checkShippingPickupActive';
import GiftCardIcon from '../../../images/giftCard.png';
import AdditionalServices from '../cart/additionalServices';
import GiftBox from '../cart/giftBox';
import SubscriptionServices from '../cart/subscriptionServices';
import PickUpDetails from '../checkout/pickUpDetails';
import PreOrder from '../pdp/product-preview/preOrder';

const Order = ({ cartList, onSubmit, giftCard, getAllCustomMessages }) => {
  const { orderConfirmation, data: cartListItems, studioConfig } = useCart();
  const [addonItemId, setAddonItemId] = useState();
  const [data, setData] = useState(null);
  const [cardInfo, setCardInfo] = useState(null);
  const [valid, setValid] = useState(false);
  const [monetateConfig, setMonetateConfig] = useState(null);
  const [giftCardPayments, setGiftCardPayments] = useState([]);
  const router = useRouter();
  const { orderId } = router.query;
  const { account, loggedIn, orderConfirmationDetails } = useAccount();
  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    if (studioConfig?.monetateConstants) {
      try {
        const monetateConfig = JSON?.parse(studioConfig?.monetateConstants?.replace(/'/g, '"'));
        setMonetateConfig(monetateConfig);
      } catch (error) {
        console.error('Errorparsing projectConfig:', error);
      }
    }
  }, [studioConfig]);

  useEffect(() => {
    if (data && monetateConfig) {
      const cartData = data?.[0];
      if (cartData) {
        sendToMonetate(
          {
            page: 'purchase',
            cart: {
              ...cartData,
              ...{
                cid: account?.cid,
              },
            },
          },
          monetateConfig,
        );
      }
    }
  }, [data, monetateConfig]);

  const filteredPayments = data?.[0]?.payments?.filter(
    (payment) => payment.cardType !== MILSTAR_CARD && payment.cardType !== GIFT_CARD_TYPE,
  );

  const milstarPayment = data?.[0]?.payments?.find(
    (payment) => payment.cardType === MILSTAR_CARD && payment.cardType !== GIFT_CARD_TYPE,
  );

  const handleOrderConfirmation = async () => {
    try {
      const responseTyped = orderConfirmationDetails as any;
      if (responseTyped.errorCode !== undefined || responseTyped.length == 0) {
        setValid(true);
        router.push('/');
      } else {
        setData(orderConfirmationDetails);
        const creditCardPayment = orderConfirmationDetails[0]?.payments.find(
          (payment) => payment.paymentMethod === 'creditCard',
        );
        setCardInfo(creditCardPayment?.cardType);
        const giftCardPayments = orderConfirmationDetails[0]?.payments.filter(
          (payment) => payment.paymentMethod === 'giftCard',
        );
        if (giftCardPayments.length) {
          const sortedGiftCardPayments = giftCardPayments.sort((a, b) => {
            return a.amountPlanned.centAmount - b.amountPlanned.centAmount;
          });
          setGiftCardPayments(sortedGiftCardPayments);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    handleOrderConfirmation();
  }, [orderConfirmationDetails]);

  const { formatMessage: formatOrderConfirmationMessage } = useFormat({ name: 'orderConfirmation' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  return (
    <>
      {!valid && (
        <section>
          <section className="block lg:flex lg:justify-between">
            <main className="w-full">
              <section className="border-[1px solid #EDEDED] mb-[15px] rounded-sm border pb-[22px]">
                <h4 className="flex h-[44px] items-center bg-[#EDEDED] px-[17px] py-3 text-affes-xl font-bold text-gray-900">
                  <CheckShippingPickupIconActive className="mr-3  h-[18px] w-[18px]" />
                  {formatOrderConfirmationMessage({ id: 'shippingPickup', defaultMessage: 'Shipping & Pick Up' })}
                </h4>

                {getSeparatedDeliverySections(data?.[0]?.displayLineItems)
                  ?.filter((lineitem) => !lineitem?.parentBundleLineItemId && !lineitem?.isOverSizeFee)
                  ?.map((item, index) => {
                    const isBundleItem = isParentBundleLineItem(item);
                    const itemShippingAddress = item?.shippingAddress
                      ? item?.shippingAddress
                      : item.shippingDetails?.shippingAddress;
                    const deliveryOptionText =
                      item?.variant?.attributes?.customProductType &&
                      EGIFT_CUSTOM_PRODUCTS?.includes(item?.variant?.attributes?.customProductType)
                        ? 'Via Email '
                        : item?.isPickUp
                        ? formatOrderConfirmationMessage({ id: 'pickup', defaultMessage: 'Pick Up In Store At' })
                        : formatOrderConfirmationMessage({ id: 'shipTo', defaultMessage: 'Ship to' });
                    let deliveryAddress = '';

                    if (!item.isPickUp) {
                      deliveryAddress += itemShippingAddress?.streetName ? itemShippingAddress.streetName + ', ' : '';
                      deliveryAddress += itemShippingAddress?.streetNumber
                        ? itemShippingAddress.streetNumber + ', '
                        : '';
                      deliveryAddress += itemShippingAddress?.city ? itemShippingAddress.city + ', ' : '';
                      deliveryAddress += itemShippingAddress?.state ? itemShippingAddress.state + ', ' : '';
                      deliveryAddress += itemShippingAddress?.postalCode ? itemShippingAddress.postalCode : '';
                    } else {
                      deliveryAddress = item?.recipientEmailAddress
                        ? item?.recipientEmailAddress
                        : item.channel?.name
                        ? item.channel.name
                        : '';
                    }
                    return (
                      <>
                        {item?.variant?.attributes?.customProductType !== 'PRODUCT_FEE' && (
                          <section className="px-[18px] pt-[22px]" key={index}>
                            {item?.shippingDetails &&
                              !(!item?.variant?.isPickupInStore && !item?.variant?.isShipToAddress) && (
                                <p className="mb-2 block text-affes-sm text-gray-900 lg:hidden">
                                  {item?.shippingDetails?.shippingInfo?.name}
                                </p>
                              )}
                            <section className="mb-5 flex">
                              <p className="mr-2 flex text-affes-sm font-bold leading-5 text-gray-900">
                                {deliveryOptionText === 'Ship to' ? (
                                  <>
                                    <span
                                      className={`${deliveryOptionText === 'Ship to' ? 'hidden md:block' : 'block'}`}
                                    >
                                      {deliveryOptionText}
                                    </span>
                                    <span>&nbsp;{deliveryAddress}</span>
                                  </>
                                ) : (
                                  <span className="block">
                                    {deliveryOptionText}
                                    <span>&nbsp;{deliveryAddress}</span>
                                  </span>
                                )}
                              </p>
                              {item?.shippingDetails && item?.shippingDetails?.shippingInfo?.name !== 'Pick Up Store' && (
                                <span className="mr-2 hidden text-affes-sm leading-5 text-gray-900 lg:block">
                                  {item?.shippingDetails?.shippingInfo?.name}{' '}
                                  {item?.shippingDetails?.shippingInfo?.description}
                                </span>
                              )}
                            </section>
                            <section className="mb-5 flex">
                              <section className="mr-3">
                                <Link
                                  href={
                                    isBundleItem
                                      ? item?._ubUrl
                                      : item?.isMarketplaceItem
                                      ? item?.slug
                                      : loggedIn
                                      ? item?._url
                                      : item?._guestUrl
                                  }
                                >
                                  <p className="relative h-[50px] w-[50px] cursor-pointer">
                                    <img
                                      src={
                                        item?.isMarketplaceItem
                                          ? item?.clientLineImageURL || item?.variant?.images?.[0]
                                          : item?.variant?.imageSet?.[FRONT_SMALL]?.src?.[0]?.url ||
                                            item?.variant?.images[0]
                                      }
                                      alt="Bundle Image"
                                      className="w-[50px]"
                                    />
                                    {item.count > 1 && (
                                      <div className="absolute top-[-12px] right-[-6px] flex h-7 w-7 items-center justify-center rounded-[50%] border-2 border-[#ffffff] bg-[#043C63]">
                                        <div className="flex items-center justify-center text-[14px] font-bold text-[#ffffff]">
                                          {item?.count}
                                        </div>
                                      </div>
                                    )}
                                  </p>
                                </Link>
                              </section>
                              <section>
                                {item?.isMarketplaceItem && (
                                  <p className=" flex items-center font-bold text-gray-900 md:border-none">
                                    <span>{item?.variant?.attributes?.vendorName}</span>
                                  </p>
                                )}
                                <Link
                                  href={
                                    isBundleItem
                                      ? item?._ubUrl
                                      : item?.isMarketplaceItem
                                      ? item?.slug
                                      : loggedIn
                                      ? item?._url
                                      : item?._guestUrl
                                  }
                                >
                                  <a className="cursor-pointer text-affes-sm font-bold text-blue-900">{item.name}</a>
                                </Link>
                                {item?.bundleItems?.length > 0 &&
                                  item?.bundleItems?.map((bundleItem, i) => (
                                    <section key={i}>
                                      <p className="mb-2 break-all text-sm font-medium leading-5">
                                        {bundleItem?.displayName} : {bundleItem?.productName}
                                      </p>
                                      {bundleItem?.personalizationLines?.length > 0 &&
                                        bundleItem?.personalizationLines?.map((personalizationLine, j) => (
                                          <p className="mb-2 break-all text-sm font-medium leading-5" key={j}>
                                            {formatCartMessage({
                                              id: 'personalization',
                                              defaultMessage: 'Personalization',
                                            })}
                                            : {personalizationLine}
                                          </p>
                                        ))}
                                    </section>
                                  ))}
                                {isPreOrderable(item) && (
                                  <PreOrder
                                    preOrderStreetDate={item?.variant?.attributes?.preOrderStreetDate}
                                    pageId="cart"
                                  />
                                )}
                                {item.isPickUp && item.showExtendedPickupMessage === true && (
                                  <section className="mt-3 mb-4 flex items-center">
                                    <img src={GiftCardIcon.src} alt="GiftCard Image" className="h-auto w-8" />{' '}
                                    <p className="ml-2 text-affes-sm leading-5 text-gray-900">
                                      {' '}
                                      {getAllCustomMessages?.receiveAGiftCard}
                                    </p>
                                  </section>
                                )}
                                {!item?.isParentBundleProduct && (
                                  <section className="hidden lg:block">
                                    <AdditionalServices
                                      isOrderConfirmed={true}
                                      lineItem={item}
                                      cartListItems={data[0]}
                                      setAddonItemId={setAddonItemId}
                                    />
                                  </section>
                                )}
                                {item?.isSubscription && !item?.isPickUp && (
                                  <section className="mb-3 hidden lg:block ">
                                    <SubscriptionServices
                                      lineItem={item}
                                      getAllCustomMessages={getAllCustomMessages}
                                      showEditSubcriptionIcon={false}
                                    />
                                  </section>
                                )}
                                {item?.isPickUp &&
                                  item?.variant?.isPickupInStore &&
                                  !EGIFT_CUSTOM_PRODUCTS?.includes(item?.variant?.attributes?.customProductType) && (
                                    <PickUpDetails item={item} isOrderConfirmed={true} />
                                  )}
                                {item?.isGift && (
                                  <section className="mb-3 block ">
                                    <GiftBox keyVal={cartList} lineItem={item} showEditIcon={false} />
                                  </section>
                                )}
                              </section>
                            </section>
                            {!item?.isParentBundleProduct && (
                              <section className="mb-3 block lg:hidden">
                                <AdditionalServices
                                  isOrderConfirmed={true}
                                  lineItem={item}
                                  cartListItems={cartListItems}
                                  setAddonItemId={setAddonItemId}
                                />
                              </section>
                            )}

                            {item?.isSubscription && !item?.isPickUp && (
                              <section className="mb-3 block lg:hidden">
                                <SubscriptionServices
                                  lineItem={item}
                                  getAllCustomMessages={getAllCustomMessages}
                                  showEditSubcriptionIcon={false}
                                />
                              </section>
                            )}
                            <hr className="border-[1px solid #EDEDED] mt-[18px] mb-[15px] border border-t" />
                          </section>
                        )}{' '}
                      </>
                    );
                  })}
              </section>
              <section className="border-[1px solid #EDEDED] mb-[15px] rounded-sm border pb-[22px]">
                <h4 className="flex items-center bg-[#EDEDED] px-[17px] py-3 text-affes-xl font-bold text-gray-900">
                  <CheckShippingPickupIconActive className="mr-3  h-[18px] w-[18px]" />
                  {formatOrderConfirmationMessage({ id: 'payment', defaultMessage: 'Payment' })}
                </h4>
                <section className="px-[18px] pt-[22px]">
                  {giftCardPayments.length > 0 && (
                    <section>
                      <section className="mb-3 flex items-center">
                        <h4 className="mr-[11px] text-affes-xl font-bold leading-[19.2px] text-gray-900">
                          {formatOrderConfirmationMessage({ id: 'gift.card', defaultMessage: 'Gift Card' })}
                        </h4>
                        <img src={GiftCardIcon.src} alt="Gift Card Icon" className="h-[24px] w-[38.24px]" />
                      </section>
                      <p className="mb-4 text-affes-sm leading-[16.8px] text-gray-800">
                        {formatOrderConfirmationMessage({
                          id: 'upto.gift.card',
                          defaultMessage: 'Up to 5 gift cards may be applied',
                        })}
                      </p>
                      {giftCardPayments.map((payment, index) => (
                        <section key={index}>
                          <section className="flex gap-x-2.5">
                            <span className="flex items-center">
                              <CheckShippingPickupIconActive className="h-[14px] w-[14px]" />
                            </span>
                            <span className="font-bold text-gray-900">
                              {' '}
                              xxxx xxxx{' '}
                              {payment?.token?.slice(payment?.token?.length > 3 ? payment?.token?.length - 3 : 0)}
                            </span>
                          </section>
                          <p className="text-[#268936]">
                            {formatOrderConfirmationMessage({
                              id: 'giftAmountText',
                              defaultMessage: 'A ${centAmount} gift card has been added successfully.',
                              values: { centAmount: (payment?.amountPlanned?.centAmount / 100).toFixed(2) },
                            })}
                          </p>
                        </section>
                      ))}
                      <hr className="mt-6" />
                    </section>
                  )}

                  {filteredPayments?.map((payment, index) => {
                    const cardInfo = getCreditCardInfo(payment);

                    return (
                      <section className="mb-6 mt-5" key={index}>
                        <section className="mb-[13px] flex items-center">
                          <h4 className="mr-[11px] text-affes-xl font-bold leading-[19.2px] text-gray-900">
                            {formatOrderConfirmationMessage({ id: 'CreditCard', defaultMessage: 'Credit Card' })}
                          </h4>
                          <p className=" h-[24px]">{cardInfo.icon}</p>
                        </section>
                        <section>
                          <section className="flex gap-x-2.5 text-sm">
                            <span className="flex items-center">
                              <CheckShippingPickupIconActive className="h-[14px] w-[14px]" />
                            </span>
                            <span className="font-bold text-gray-900">{cardInfo.text}</span>
                          </section>
                        </section>
                      </section>
                    );
                  })}

                  {milstarPayment && (
                    <section className="mb-6 mt-5">
                      <section className="mb-[13px] flex items-center">
                        <h4 className="mr-[11px] text-affes-xl font-bold leading-[19.2px] text-gray-900">
                          {formatOrderConfirmationMessage({ id: 'CreditCard', defaultMessage: 'Credit Card' })}
                        </h4>
                        <p className=" h-[24px]">{getCreditCardInfo(milstarPayment).icon}</p>
                      </section>
                      <section>
                        <section className="flex gap-x-2.5 text-sm">
                          <span className="flex items-center">
                            <CheckShippingPickupIconActive className="h-[14px] w-[14px]" />
                          </span>
                          <span className="font-bold text-gray-900">{getCreditCardInfo(milstarPayment).text}</span>
                        </section>
                      </section>
                    </section>
                  )}

                  {cardInfo && (
                    <>
                      {Object.keys(data?.[0]?.billingAddress || {}).length > 0 && (
                        <section>
                          <section className="items-top mt-5 flex">
                            <h3 className="mb-[10px] mr-3 text-affes-xl font-bold leading-[19.2px] text-gray-800">
                              {formatOrderConfirmationMessage({
                                id: 'BillingAddress',
                                defaultMessage: 'Billing Address',
                              })}
                            </h3>{' '}
                          </section>
                          <p className="mb-3 text-affes-sm font-[600] leading-[16.8px] text-gray-900">
                            {data?.[0]?.billingAddress?.firstName} {data?.[0]?.billingAddress?.lastName}{' '}
                          </p>
                          <p className="text-affes-sm leading-[16.8px] text-gray-900">
                            {' '}
                            {data?.[0]?.billingAddress?.streetName} {data?.[0]?.billingAddress?.streetNumber},{' '}
                            {data?.[0]?.billingAddress?.city}, {data?.[0]?.billingAddress?.state},{' '}
                            {data?.[0]?.billingAddress?.country}, {data?.[0]?.billingAddress?.postalCode}
                          </p>
                        </section>
                      )}
                    </>
                  )}
                </section>
              </section>
            </main>
          </section>
        </section>
      )}
      {showLoader && <Loader />}
    </>
  );
};

export default Order;
