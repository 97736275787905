import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { customURL } from 'helpers/constants/aafes';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import { isParentBundleLineItem } from 'helpers/utils/ub-utils';
import { useAccount, useCart } from 'frontastic';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import CartIcon from '../../../icons/cart';
import CartIconMobile from '../../../icons/cart-mobile';
import ItemPrice from '../cart/itemPrice';
import Price from '../price';
function MiniCart({ cartList, showLogin }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isMiniCartOpen: showMiniCart, setIsMiniCartOpen: setShowMiniCart } = useCart();
  const { loggedIn } = useAccount();
  const { studioConfig, setCartProductDetailEventSent } = useCart();
  const [isCart, setIsCart] = useState(false);
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const router = useRouter();
  const [monetateConfig, setMonetateConfig] = useState(null);
  const [monetateSent, setMonetateSent] = useState(false);
  const cartDisplayLineItems = cartList?.displayLineItems || [];

  const miniCartProducts = [];
  function shouldExcludeKey(key, excludedPatterns) {
    return excludedPatterns.some((pattern) => key.endsWith(`_${pattern}`));
  }

  function extractValues(item, valuesArray, excludedPatterns = []) {
    Object.keys(item).forEach((key) => {
      if (!shouldExcludeKey(key, excludedPatterns)) {
        const value = item[key];
        if (Array.isArray(value)) {
          value.forEach((subItem) => {
            if (typeof subItem === 'object' && subItem !== null) {
              valuesArray.push(subItem);
            }
          });
        } else if (typeof value === 'object' && value !== null) {
          extractValues(value, valuesArray, excludedPatterns);
        } else {
          valuesArray.push(value);
        }
      }
    });
  }

  cartDisplayLineItems.slice(0, 3).forEach((item) => {
    if (typeof item === 'object' && item !== null) {
      extractValues(item, miniCartProducts, ['totalCount']);
    }
  });

  useEffect(() => {
    const location = window ? window?.location?.pathname : '';
    if (location.indexOf('cart') > -1 || location.indexOf('order-confirmation') > -1) {
      setIsCart(true);
    } else {
      setIsCart(false);
    }
  });
  useEffect(() => {
    if (studioConfig?.monetateConstants) {
      try {
        const monetateConfig = JSON?.parse(studioConfig?.monetateConstants?.replace(/'/g, '"'));
        setMonetateConfig(monetateConfig);
      } catch (error) {
        console.error('Errorparsing projectConfig:', error);
      }
    }
  }, [studioConfig]);

  useEffect(() => {
    const processMonetate = async () => {
      const callMonetate = async () => {
        setMonetateSent(true);
        await sendToMonetate(
          {
            page: 'minicart',
            cart: {
              ...cartList,
            },
          },
          monetateConfig,
        );
      };
      if (showMiniCart && cartList && monetateConfig && !monetateSent) {
        await callMonetate();
        setCartProductDetailEventSent(true);
      }
    };
    const fetchData = async () => {
      await processMonetate();
    };
    fetchData();
  }, [cartList, monetateConfig]);

  return (
    <>
      <section className="order-6 flex md:hidden">
        {loggedIn ? (
          <div className="self-center">
            <Link href={customURL?.cartUrl}>
              <p className="relative flex cursor-pointer">
                <span
                  className={`${
                    miniCartProducts?.length
                      ? 'absolute -right-2 -top-3 block h-6 w-6 rounded-full bg-[#B81335] text-center align-middle text-affes-sm font-bold leading-6 text-white'
                      : 'hidden'
                  }`}
                >
                  {cartList?.totalLineItemCount}
                </span>
                <CartIcon className="hidden md:block" />
                <CartIconMobile className="block md:hidden" />
              </p>
            </Link>
          </div>
        ) : (
          <button onClick={() => showLogin()}>
            <CartIcon className="hidden md:block" />
            <CartIconMobile className="block md:hidden" />
          </button>
        )}
      </section>
      <section className="order-6 hidden items-center md:flex" aria-label="Shopping Cart">
        {loggedIn && miniCartProducts?.length ? (
          <>
            <section
              className="relative"
              onMouseEnter={() => {
                miniCartProducts.length ? setShowMiniCart(true) : setShowMiniCart(false);
              }}
              onMouseLeave={() => setShowMiniCart(false)}
            >
              <Link href={customURL?.cartUrl}>
                <p className="relative flex cursor-pointer">
                  <span
                    className={`${
                      cartList?.totalLineItemCount
                        ? 'absolute -right-2 -top-3 block h-6 w-6 rounded-full bg-[#B81335] text-center align-middle text-affes-sm font-bold leading-6 text-white'
                        : 'hidden'
                    }`}
                  >
                    {cartList?.totalLineItemCount}
                  </span>
                  <button>
                    <CartIcon className="hidden md:block" />
                    <CartIconMobile className="block md:hidden" />
                  </button>
                </p>
              </Link>

              {showMiniCart && !isCart && (
                <>
                  <section className="absolute right-0 top-5 z-50 w-[90%] border border-[#ebebeb] bg-white md:w-[400px]">
                    <section className="h-[361px] bg-white">
                      <section className="h-[calc(361px_-_113px)] overflow-y-auto pl-[15px]">
                        <ul className="divide-y divide-[#ebebeb]">
                          {miniCartProducts
                            ?.filter((cartItem) => !cartItem?.parentBundleLineItemId)
                            ?.map(
                              (item, index) =>
                                !item?.isAttachment && (
                                  <li className="py-3" key={index}>
                                    <section className=" w-[300px]">
                                      <section>
                                        <section className="mb-3 w-24">
                                          <Link href={isParentBundleLineItem(item) ? item?._ubUrl : item?._url}>
                                            <img
                                              src={item?.clientLineImageURL || item?.variant?.images?.[0]}
                                              alt="Item Variant Image"
                                              height={60}
                                              width={60}
                                            />
                                          </Link>
                                        </section>
                                        <section className="itemDetails">
                                          <h2 className="mb-4 text-affes-xl">
                                            <Link href={isParentBundleLineItem(item) ? item?._ubUrl : item?._url}>
                                              <a className="cursor-pointer font-semibold text-gray-900">{item?.name}</a>
                                            </Link>
                                          </h2>
                                        </section>
                                        <ItemPrice item={item} view="miniCart"></ItemPrice>
                                        <section className="itemDetails">
                                          <p className={` mb-[5px] text-[12px] leading-[20px] text-[#717171]`}>
                                            {formatCartMessage({ id: 'qty', defaultMessage: 'QTY:' })} {item?.count}
                                          </p>
                                        </section>
                                      </section>
                                    </section>
                                  </li>
                                ),
                            )}
                        </ul>
                      </section>

                      <section className="w-full border-t border-[#ebebeb] bg-white p-[15px]">
                        <p className="mb-[15px] flex items-center justify-center text-[12px] font-bold text-gray-900">
                          {formatCartMessage({ id: 'subtotal', defaultMessage: 'Subtotal' })} (
                          {cartList?.totalLineItemCount}{' '}
                          {formatCartMessage({ id: 'minicartitems', defaultMessage: 'Items' })}){' '}
                          <Price
                            price={cartList?.total || {}}
                            className="ml-6 text-affes-xl font-bold  text-red-900 dark:text-light-100"
                          />
                        </p>
                        <section className="flex  justify-center">
                          <button onClick={() => (setShowMiniCart(false), router.push(customURL?.cartUrl))}>
                            <p className="w-[166px] cursor-pointer rounded bg-blue-900 px-4 py-2 text-center text-[18px] font-bold text-white">
                              {formatCartMessage({ id: 'checkout', defaultMessage: 'Checkout' })}
                            </p>
                          </button>
                        </section>
                      </section>
                    </section>
                  </section>
                </>
              )}
            </section>
          </>
        ) : (
          <>
            {loggedIn ? (
              <Link href={customURL?.cartUrl}>
                <p className="cursor-pointer">
                  <CartIcon className="hidden md:block" />
                  <CartIconMobile className="block md:hidden" />
                </p>
              </Link>
            ) : (
              <button onClick={() => showLogin()} aria-label="Open Cart">
                <CartIcon className="hidden md:block" />
                <CartIconMobile className="block md:hidden" />
              </button>
            )}
          </>
        )}
      </section>
    </>
  );
}

export default MiniCart;
